html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body{
    background:  var(--background-color);
}

p {
    font-family: 'Averia Serif Libre', cursive;
    color: rgb(152, 151, 151);
    line-height: 1.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}
