.indieBlogCom{
    width: 30%;
    height: 20vh;
    display: flex;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    padding: 0 20px;
    background: #fff;
    border-radius: 15px;
    font-weight: bolder;
    cursor: pointer;
    margin-bottom: 20px;
}
.indieBlogCom:hover{
    transform: scale(1.2) translateY(-7px);
    color: #5ac8fa;
}

@media screen and (max-width: 480px) {
    .indieBlogCom{
        width: 100%;
    }
}
