.sidebar {
    background: #182968;
    width: 18vw;
    height: 100vh;
    border-right: 1px solid #007ac1;
    margin-right: 25px;
    padding: 20px 10px;
    position: sticky;
    top: 0;
}

.sidebar h2 {
    color: #F5F5F5;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 10vh;
    margin-top: 20px;
}

.profile {
    margin: 50px 0;
    font-weight: bolder;
}

.profile img {
    width: 50px;
    /*height: 60px;*/
    border-radius: 50%;
    margin-right: 15px;
}

.navLinks {
    cursor: pointer;
}

.navLink {
    color: #F5F5F5;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.navLink:hover {
    background-color: #077cc3;
    text-decoration: none;
    color: #fff;
}

.activeLink {
    background-color: #077cc3;
    color: #fff;
}
