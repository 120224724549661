.reviewQCon{
    margin: 50px 0 20px;
}
.reviewQCon h3{
    font-size: 2rem;
    margin-bottom: 40px;
}

.reviewInpCon label{
    font-weight: bolder;
    font-size: 1.2rem;
    color: #444;
}

.reviewTA{
    display: flex;
    flex-direction: column;
}
