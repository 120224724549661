
.dashInpCom{
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.dashInpCom label{
    margin-top: 40px;
}
.dashInpCom input {
    background: #f2f4f8;
    border: 0;
    border-bottom: 1px solid #555;
    border-radius: 0;
    min-width: 30vw;
}
.dashInpCom input:focus {
    background: #f2f4f8;
    border-bottom: 2px solid #077cc3;

}
