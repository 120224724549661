@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

.firstBanner {
    background: url("../../../Components/Assests/Home/b_new.jpg") no-repeat center;
    background-size: cover;
    height: 80vh;
}

.BannerText {
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 102, 102, 0) 0%, #000000 100%);

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff
}

.bannerBtn {
    display: flex;
    gap: 20px;
    margin: 10px auto;
}

.bannerBtn div {
    cursor: pointer;
}

.categories {
    max-width: 100%;
    padding: 50px 8%;
    background: var(--background-color);
    /*margin: 10px 1%;*/
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1)
}

.catHead {
    text-align: center;
    margin-bottom: 8vh;
    font-family: 'Roboto', sans-serif;
    color: var(--foreground-color);
}

.subHead {
    background: linear-gradient(90deg, #052578 0%, #0068BF 100%);
    margin: 0 auto;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 16px;
    color: #fff;
}

.catHead h2 {
    color: var(--foreground-color);
    font-size: 40px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.catRoundCom {
    display: flex;
    justify-content: space-between;
}

.catRoundCom div img:hover {
    /*box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.20);*/
    transform: scale(1.2) translateY(-7px);
    /*border: 3px solid #022976;*/
    filter: drop-shadow(0 12px 10px var(--shadow-color));
}

.catRoundCom div img.notActive:hover {
    filter: grayscale(100%) drop-shadow(0 12px 10px #E2E6EA);
}

.catRoundCom div img.notActive {
    filter: grayscale(100%);
    cursor: no-drop;
}

.catRoundCom div img {
    border-radius: 50%;
    cursor: pointer;
    width: 100px;
    background: transparent;
}

.topSellingProduct {
    padding: 70px 8% 20px;
    background: var(--background-color);
    height: 80vh
}

.indieCarProduct h4[data-title]:after, .indieSerProduct h4[data-title]:after {
    content: attr(data-title);
    background-color: var(--background-color);
    color: var(--foreground-color);
    left: 0;
    bottom: -40px;
    width: 18vw;
    text-wrap: unset;
}

.topSellingProduct .indieCarProduct, .ProductCollection .indieCarProduct {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 99, 186, 0.3) 0%, rgba(4, 40, 124, 0.3) 100%);
    color: #fff;
    margin: 0 35px;
    padding: 20px 35px;
    border-radius: 10px;
    /*font-family: 'Roboto', sans-serif;*/
    border: 3px solid #0565B8;
}

.ProductCollection .indieCarProduct{
    padding: 5px 15px;
    margin: 0 5px;
}

/*.topSellingProduct .indieCarProduct:hover, .ProductCollection .indieCarProduct:hover{*/
/*    transform: scale(1.1) translateY(-7px);*/

/*}*/

.topSellingProduct .indieCarProduct img {
    /*height: 23vh;*/
}

.carousel-button-group {
    position: relative;
    top: -42vh;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.carousel-button-group button {
    height: 40vh;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: 24px;
    transition: background 0.4s;
}

.carousel-button-group button svg {
    color: var(--foreground-color);
}

.carousel-button-group button:focus-visible, .carousel-button-group button:focus {
    outline: none;
}

.carousel-button-group button:hover {
    background: var(--shadow-color);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1)
}

.indieCarProduct h4, .indieSerProduct h4 {
    margin-top: 10px;
    color: #022976;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;

}

.indieCarProduct h4:hover {
    color: #5ac8fa;
}


.indieCarProduct span, .indieSerProduct span {
    display: block;
}

.indieCarProduct .price {
    font-size: 24px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;

}

.indieCarProduct .checkStore, .indieSerProduct .checkStore {
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
    padding: 5px 10px;
    margin: 20px 0 5px;
    text-align: center
}

.indieCarProduct .checkStore:hover, .indieSerProduct .checkStore:hover {
    color: #5ac8fa;
}

.indieCarProduct .rating {
    margin-top: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.topSellingProduct img, .ProductCollection img {
    width: 100%;
}


.SpecialityIconCom {
    background: var(--background-color);

    font-family: 'Roboto', sans-serif;
    padding: 20vh 0 12vh;
}

.curve{
    width: 100vw;
    height: 80px;
}


.iconsRow{
    background: #fff;
    margin: -39px 0;
    padding: 10vh 15%;
    display: flex;
    justify-content: space-between;
}

.iconsRow div{
    background: linear-gradient(90deg, #052578 0%, #0068BF 100%);
    color: #fff;
    padding: 20px 40px;
    border-radius: 0 20px 0 20px;
    width: 30%;
}
.iconsRow div p{
    font-size: 12px;
}

.SpecialityIconCom div img {
    height: 108px;
}

.SpecialityIconCom div {
    text-align: center;
}

.SpecialityIconCom h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 28px;
    margin: 15px 0 0px;
}

.SpecialityIconCom span {
    display: inline-block;
    width: 75%;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 300;

}

.banner {
    padding: 10vh 8%;
    display: flex;
    justify-content: space-between;
}

.banner img {
    border-radius: 9px;
    max-width: 100%;
}

.ProductCollection {
    padding: 30px 8% 80px;
    background: var(--background-color);
    height: auto;
}

.ProductCollectionCarousel {
    height: 80vh;
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.react-multi-carousel-list {
    z-index: 20;
}

.ProductCollection img {
    border-radius: 9px;

}

.collectionUnderOffer {
    padding: 30px 8% 35px;
    background: var(--background-color);
}

.collectionUnderOffer div {
    text-align: center;
}

.collectionUnderOffer img {
    border-radius: 5px;
    width: 90%;
}

.ProductCom {
    padding: 75px 8%;
    background: #fff;
    margin: 2vh 1% 2vh;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1)

}

.ProductCom img {
    border-radius: 5px;
}

.ProductCom .catHead {
    text-align: left;
    position: sticky;
    top: 12vh;
}

.ProductCom p {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
}

.iconsCon{
    background: var(--background-color);
}

.iconsCon .main{
    padding: 60px 30%;
    margin: -30px 0;
    background: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.indieIcons {
    width: 64px;
    height: 64px;
}

.indieIcons:hover {
    color: #5ac8fa;
}

.iconsCon .numb {
    font-weight: 400;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    /*width: 60px;*/
}

.iconsCon .numb span {
    font-weight: 400;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;

}

.iconsCon span {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 24px;
}

.iconsCon div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
}

.testimonial {
    padding: 65px 25% 30px;
    color: var(--foreground-color);
    background: var(--background-color);
    font-family: 'Roboto', sans-serif;
}

.testimonial .reviewsCom img {
    border-radius: 50%;
    width: 100px !important;
}

.testimonial .reviewsCom p {
    width: 60%;
    margin: 15px auto;
    font-family: 'Roboto', sans-serif;

}

.testimonial .reviewsCom h3 {
    font-size: 1.1rem;
}
