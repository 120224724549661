.headMain {
    padding: 20px 6%;
    background: var(--background-color);
    /*position: sticky;*/
    border-bottom: 1px solid #fff;
    /*top: 0;*/
    /*z-index: 100;*/
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.headContainer {
    display: flex;
    align-items: center;
    /*color: #fff;*/
    background: transparent;
    justify-content: space-between;
    /*border-bottom: 2px solid #fff;*/
}

.logo {
    display: flex;
    /*align-items: flex-end;*/
}

.logoName {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-top: 10px;
}

.logo:hover {
    text-decoration: none;
}

.leftCom {
    display: flex;
    align-items: center;
    gap: 25px;
}

.leftCom a {
    color: var(--text-color);
}

.rightCom {
    display: flex;
    gap: 45px;
}

.menuContainer {
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 40px -10px;
}

.menuContainer svg {
    font-size: 1.8rem
}

.menuContainer span {
    position: relative;
    bottom: 10px;
}

.locationContainer {
    font-size: 0.8rem;
    cursor: pointer;
    color: var(--text-color);
    display: flex;
}

.locationContainer svg {
    font-size: 1.3rem
}

.locationContainer span {
    position: relative;
    bottom: 4px;
    margin-left: 5px;
    /*height: 100px;*/

}

.locEditIcon {
    font-size: 0.8rem !important;
    margin-left: 3px;
    position: relative;
    bottom: 2px;
}

.changePinInp {
    border: 0;
    display: inline;
    width: 50px;
    /*height: 100px;*/
    border-bottom: 1px solid #5ac8fa;
}

.changePinInp:focus-visible {
    outline: none;
}

.EditPinCodeIcon {
    font-size: 1rem
}

.EditPinCodeIcon:hover {
    color: #5ac8fa;
}

.searchBox {
    background: var(--background-color);
    display: flex;
    align-items: center;
    border-radius: 32px;
    border: 1px solid var(--foreground-color);
    /*padding: 5px 0;*/
}

.searchBox input {
    padding: 5px 5px 5px 15px;
    border: 0;
    width: 35vw;
    color: var(--foreground-color);
    height: 40px;
    background: var(--background-color);
    border-radius: 32px 0 0 32px;
}

.searchBox input::placeholder {
    color:var(--foreground-color)
}

.searchBox input:focus-visible {
    outline: none;
}

.searchBoxIcon {
    font-size: 1.5rem;
    /*margin: 5px;*/
    height: 40px;
    width: 40px;
    border-radius: 0 32px 32px 0;
    padding: 0 5px;
    cursor: pointer;
}

.searchBoxIcon:hover {
    background: #5ac8fa;
}
.accountContainer{
    cursor: pointer;
}
.accountContainer svg, .sellerContainer svg {
    font-size: 1.8rem;
    position: relative;
    bottom: -5px;
    cursor: pointer;
    margin-right: 5px;
}

.accountContainer span, .sellerContainer span {
    color: var(--text-color)
}

.showMenuContainer {
    width: 99vw;
    height: 90vh;
    position: absolute;
    left: 0;
    top: 11vh;
    background: #000;
    opacity: 0.6
}

.menuMainCon, .accountCom {
    /*height: 80vh;*/
    width: 20vw;
    position: absolute;
    left: 20vw;
    opacity: 1;
    background: #00469c;
    top: 11vh;
    color: #fff;
    padding: 10px 25px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1)
}

.accountCom {
    left: 77vw;
    width: 20vw;
    padding: 10px 15px;
}

.menuMainCon h4 {
    font-weight: bolder;
}

.menuCategories {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.menuCategories span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    padding: 5px 10px;
}

.menuCategories span:hover {
    background: #5ac8fa;
}

.productSuggestion {
    z-index: 100;
    position: absolute;
    top: 180px;
    left: 30vw;
    width: 38vw;
    background: var(--background-color);
    padding: 20px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1)

}

.productSuggestion span {
    display: block;
    padding: 10px 5px;
    margin-bottom: 5px;
    color: var(--foreground-color);
}

.productSuggestion span:hover {
    background: var(--foreground-color);
    cursor: pointer;
    color: var(--background-color);
}
