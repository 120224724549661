.footer{
    background: linear-gradient(0deg, #03196D 0%, var(--background-color) 100%);
    padding: 7vh 10%;
    color: #fff;
    font-family: 'Averia Serif Libre', cursive;
    overflow-x: hidden;
    width: 103.5%;
}

.footerSec{
    border-right: 1px solid #fff;
}

.footHeadTxt{
    font-weight: bolder;
}

.footerInp{
    background: #fff;
    width: 80%;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}
.footerInp input{
    flex: 1;
    border: 0;
}
.footerInp input:focus-visible {
    outline: none;
}

.footerInp svg{
    color: #000;
    font-size: 1.4rem;
}

.emailSubIcon{
    cursor: pointer;
}

.emailSubIcon:hover{
    color: #5ac8fa;
}

.social-icon-list {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    width: 80%;
}

.socialLinks{
    color: #FFF;
    font-size: 25px;
}

.redHover:hover{
    color: red
}

.usefulLinks{
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;

}
.usefulLinks span{
    cursor: pointer;
    width: auto;
}
.usefulLinks span:hover{
    color: #5ac8fa;
}
