@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

.CategoryImg {
    height: 60vh;
    width: 100%
}

.bannerCom {
    height: 60vh;
    width: 100%;
    /*background-size: 100% 100%;*/
}

.bannerCom div {
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 102, 102, 0) 0%, #000000 100%);

}

.searchCom {
    padding: 75px 8%;
    background: var(--background-color);
}

.filterCom {
    position: sticky;
    top: 5vh;
}

.filterHead {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 99, 186, 0.3) 0%, rgba(4, 40, 124, 0.3) 100%);
    color: #fff;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    border: 3px solid #0565B8;
    border-radius: 10px;
}

.filterHead h3 {
    font-weight: 600 !important;
    font-size: 24px;
    margin-bottom: 0;
}

.filIcon {
    font-size: 25px;
    /*box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);*/
    filter: drop-shadow(0 4px 4px #555);
}

.FilList {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 99, 186, 0.3) 0%, rgba(4, 40, 124, 0.3) 100%);
    border: 3px solid #0565B8;
    border-radius: 10px;
    padding: 15px 30px;

    color: #fff;

    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
}

.IndieFilterCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 22px;
}

.subFilCon{
    border-bottom: 1px solid #F4F1EC;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.subFilCon span {
    display: block;
}

.checkInp{
    margin-right: 8px;
}
.checkbox.style-b {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox.style-b input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkbox.style-b input:checked ~ .checkbox__checkmark {
    background-color: #273449;
}
.checkbox.style-b input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
}
.checkbox.style-b:hover input ~ .checkbox__checkmark {
    background-color: #273449;
}
.checkbox.style-b:hover input:checked ~ .checkbox__checkmark {
    background-color: #273449;
}
.checkbox.style-b .checkbox__checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #273449;
    border: 2px solid #333;
    transition: background-color 0.25s ease;
    border-radius: 4px;
}
.checkbox.style-b .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
}
.checkbox.style-b .checkbox__body {
    color: #fff;
    /*line-height: 1.4;*/
    font-size: 16px;
    /*font-weight: bold;*/
}
.searchSidePanel {
    background: #E5E5E5;
    width: 100%;
    height: 10vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 7px;
    padding: 10px 20px;
    elevation: above;
    margin-bottom: 10vh;
    position: sticky;
    top: 20px;
    z-index: 10;
}

.ProductsCom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}

.indieSerProduct {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-bottom: 35px;
    padding: 20px 35px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 99, 186, 0.3) 0%, rgba(4, 40, 124, 0.3) 100%);
    border: 3px solid #0565B8;
    color: #fff;

}
.indieSerProduct .price{
    font-size: 24px;
    margin: 20px 0 0;
}
.indieSerProduct h4:hover {
    color: #5ac8fa;
    cursor: pointer;
}




