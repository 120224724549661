:root{
    --background-color: #000;
    --text-color: #fff;
    --foreground-color: #fff;
    --shadow-color: #555
}

[data-theme="light"]{
    --background-color: #fff;
    --text-color: #000;
    --foreground-color: #000;
    --shadow-color: #E2E6EA
}
