@media screen and (max-width: 480px) {
    .sellersListCon div, .sellersListConHead div{
        /*width: 40vw;*/
    }

    .sellersListCon, .sellersListConHead{
        width: 180vw;
    }
    .priceTable{
        overflow-x: scroll;
    }
}

