.productMainCom{
    background:  var(--background-color);;
}

.productImgCom {
    text-align: center;

}

.productImgCar {
    z-index: 0 !important;
    margin-top: 20px;
}

.productImgCar li {
    margin-right: 10px;

}

.carouselImaCom {
    height: 100%;
    max-width: 100%;
}

.carouselImages {
    width: 100%;
    height: 100%;
}

.productDetails{
    color: var(--text-color);
}

.proTitle {
    font-weight: bolder;
    font-size: 1.9rem;
}

.sellersListCon, .sellersListConHead {
    display: flex;
    justify-content: space-around;
    /*align-items: center;*/
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    elevation: above;
    margin-top: 25px;
    background: linear-gradient(180deg, #0565B8 0%, #03196D 100%);
    border-radius: 5px;
    color: #fff;
}

.sellersListCon{
    margin-top: 0;

}


.sellersListConHeadDiv, .sellersListConDiv {
    width: 17%;
    text-align: center;
    border-right: 2px solid #fff;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 5vh;*/
    /*overflow-y: auto;*/
}
.sellersListConDiv:last-child, .sellersListConHeadDiv:last-child{
    border: 0;
}
.sellersListConHead {
    font-weight: bolder;

}

.contactBtn {
    font-size: 1rem;
    padding: 5px 15px;
    background: #026CC0;
    cursor: pointer;
    color: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

}


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #026CC0;
    color: #fff;
    font-size: 1rem;
    position: absolute;
    padding: 1px 5px 2px 5px;
    left: -35%;
    bottom: 2em;
    white-space: nowrap;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    border-radius: 3px;
}

[data-title] {
    position: relative;
}

.authCon {
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    background: #fff;
    margin-top: 25px;
    padding: 10px 25px;
    border-right: 5px;
}

.authCon span:hover {
    color: #5ac8fa;
}

.blur {
    filter: blur(4px);
}

.relatedProductHead{
    color: var(--text-color);
}
