.adminInpCon input{
    min-width: 5vw;
}
.adminInpCon {
    margin-top: 1vh;

}
.adminInpCon div{
    margin-top: 2vh;

}

.modelHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modelHead h2 {
    font-weight: bolder;
    color: #182968;
}

.closeIcon {
    font-size: 2rem;
    cursor: pointer;
}

.closeIcon:hover {
    color: #077cc3;
}
.loadingCon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0 25px;
    font-weight: bolder;
}

.canBtn{
    margin-right: 20px;
    background: red;
}
.canBtn:before{
    background: red;
}
