.productSearchBox {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    border-radius: 7px;
    padding: 10px 20px;
    elevation: above;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background: #fff;
}

.productSearchBox:hover {


}

.iconBtn {
    background: #fff;
    border: none;
    font-size: 1.7rem;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.iconBtn:hover {
    color: #077cc3;
}

.iconBtn:focus {
    outline: 0;

}
