@media screen and (max-width: 480px){
    .headContainer{
        /*display: none;*/
    }
    .menuContainer{
        margin-right: 0;
    }

    .searchBox{
        margin-top: 15px;

    }

    .searchBox input{
        width: 100%;
    }

    .menuMainCon{
        width: 80vw;
        left: 10vw;
    }

    .productSuggestion{
        top: 20vh;
        left: 5vw;
        width: 90vw;
    }
}
