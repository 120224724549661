@media screen and (max-width: 480px) {
    .carouselItemContainer {
        height: 35vh;
    }

    .BannerText{

    }

    .subHead{
        font-size: 14px;
    }

    .catHead h2 {
        font-size: 30px;
    }

    .categories {
        padding: 40px 2%;
    }

    .catRoundCom {
        overflow-x: scroll;
        padding: 0 0 20px;
    }

    .catRoundCom div{
        margin-right: 10px;
    }

    .catRoundCom div img {
        width: 89px;
    }

    .topSellingProduct {
        padding: 40px 10%;
        height: 80vh;
    }

    .topSellingProduct .indieCarProduct, .ProductCollection .indieCarProduct{
        margin: 0 5px;
    }


    .indieCarProduct img{
        height: 18vh !important;
    }

    .indieCarProduct .checkStore{
        font-size: 14px;
    }

    .indieCarProduct .rating{
        font-size: 14px;
    }

    .indieCarProduct h4, .indieSerProduct h4{
        font-size: 16px;
    }

    .indieCarProduct .price{
        font-size: 18px;
    }


    .SpecialityIconCom {
        /*flex-direction: column;*/
        padding: 20px 0 8px;
    }

    /*.SpecialityIconCom div {*/
    /*    margin: 13px 0;*/
    /*}*/

    .iconsRow{
        padding: 10vh 3%;
    }
    .iconsRow div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 4px;
        font-size: 12px;
    }

    .SpecialityIconCom div img {
        height: 30px;
    }

    .SpecialityIconCom h3 {
        margin: 0 0 20px;
        font-size: 18px;
    }

    .banner {
        flex-direction: column;
        padding: 30px 4%;
    }
    .banner div {
       max-width: 100%;
        margin-bottom: 25px;
    }

    .ProductCollection{
        padding: 30px 4%;
    }
    .ProductCollectionCarousel{
        margin-top: 50px;
        padding: 0 10vw;
        height: 70vh;
    }

    .collectionUnderOffer {
        padding: 30px 8% 35px;
    }

    .offerBanner{
        flex-wrap: wrap;
    }

    .offerBanner div{
        width: 42vw;
        margin-bottom: 20px;
    }
    .offerBanner div img{
        width: 100%;
    }

    .iconsCon{
        /*flex-direction: column;*/
        align-items: center;
        padding: 5px 0 0;
    }
    .iconsCon .main{
        padding: 60px 3%;
    }

    .indieIcons{
        width: 40px;
        height: 40px;
    }

    .iconsCon .numb span{
        font-size: 24px;
    }
    .iconsCon span{
        font-size: 16px;
    }

    .testimonial{
        padding: 25px 4% 10px;
    }
    .testimonial .reviewsCom p{
        width: 80%;
    }
}
