@media screen and (max-width: 480px) {
    .footer {
        padding: 30px 5%;
        flex-direction: column-reverse;
    }

    .footerSec {
        border-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        text-align: center;
    }

    .footerInp input {
        text-align: center;
    }
    .footerLinkCom{
        text-align: center;
        max-width: 100%;
        margin: 0 !important;
    }

    .usefulLinks span{
        margin: 4px 0;
    }
}
