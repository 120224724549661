.topBar{
    margin-top: 20px;
    padding-bottom: 35px;
}
.topBar .head{
    font-size: 3rem;
    color: #182968;
    font-weight: bolder;
}
.topBar .subHead{
    font-weight: 500;
}
