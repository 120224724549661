.modelHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeIconS {
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
}

.closeIconS:hover {
    color: #d9534f;
}

.contactNo {
    padding: 25px 0;
    color: #555;
}

.contactNo a {
    color: #555;
    font-family: 'Averia Serif Libre', cursive;

}

.contactNo a:hover {
    color: #077cc3;
    text-decoration: none;
    font-weight: bolder;
}
.address{
    margin: 0;
    align-items: center;
}
.addressDetail{
    background: #fff;
    color: #03196D;
    border-radius: 10px;
    height: 100%;
    padding: 10px 20px;
}
.address span {
    font-family: 'Averia Serif Libre', cursive;
    /*color: rgb(152, 151, 151);*/
    line-height: 1.5;
    font-size: 18px;
}
.addressDetail h3{
    font-size: 34px;
    font-weight: bolder;
}
.addressDetail a{
    font-size: 20px;
    display: block;
}
