.listSellerDiv{
    width: 25%;
}

.eyeIconCom{
    font-size: 25px;
    text-align: center;
    cursor: pointer;
}

.eyeIconCom svg:hover{
    color: #077cc3;
}
