.button-3d {
    display: inline-block;
    position: relative;
    border: 0;
    background: #182968;
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0,0,0.58,1),
    background 0.3s cubic-bezier(0,0,0.58,1);
    cursor: pointer;
    color: #fff;
}
.button-3d:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #182968;
    border-radius: inherit;
    transform: translate3d(0, 0.5em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.button-3d:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    filter: blur(4px);
    border-radius: inherit;
    top: 0;
    left: 0;
    transform: translate3d(0, .7em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.button-3d:hover {
    transform: translateY(-0.25em);
}
.button-3d:hover:before {
    transform: translate3d(0, 0.75em, -1em);
}
.button-3d:hover:after {
    transform: translate3d(0, 1.05em, -1em);
}
.button-3d:active {
    transform: translate(0, 0.5em);
}
.button-3d:active:before,
.button-3d:active:after {
    transform: translate3d(0,0,-1em);
}
