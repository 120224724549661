.search {
    background-color: #182968;
    padding: 4px;
    border-radius: 5px
}

.search-1 {
    position: relative;
    width: 100%
}

.search-1 input {
    height: 45px;
    border: none;
    width: 100%;
    padding-left: 34px;
    padding-right: 10px;
    border-right: 2px solid #eee
}

.search-1 input:focus {
    box-shadow: none;
    outline: none
}

.searchIcon {
    position: absolute;
    top: 12px;
    left: 5px;
    font-size: 24px;
    color: #4192c1
}

::placeholder {
    color: #4192c1;
    opacity: 0.3
}

.search-2 {
    position: relative;
    width: 100%
}

.search-2 select {
    height: 45px;
    border: none;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    color: #4192c1

}

.search-2 select:focus {
    box-shadow: none;
    outline: none
}

@media (max-width:800px) {
    .search-1 input {
        border-right: none;
        border-bottom: 1px solid #eee
    }

}
