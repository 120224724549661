.authCom {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2e8bc1;
    height: 100vh;
}

.authCenterCom {
    padding: 60px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}
.authHead{
    text-align: center;
}

.inputCon{
    margin-top: 5vh;
}
.inputCon div{
    margin-top: 4vh;
}

.inputCon input, .inputCon textarea, .inputCon select {
    background: #f2f4f8;
    border: 0;
    border-bottom: 1px solid #555;
    border-radius: 0;
    min-width: 30vw;
}

.inputCon select{
    min-width: 20vw;
    height: 35px;
}

.inputCon input:focus, .inputCon select:focus {
    background: #f2f4f8;
    border-bottom: 2px solid #077cc3;
}

.logBtn{
    cursor: pointer;
    background: #4192c1;
    border-color: #077cc3;
}
.logBtn:hover{
    background: #077cc3;
}
.btn-success:focus{
    box-shadow: 0 0 0 3px #077cc3;
}
