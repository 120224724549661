@media screen and (max-width: 480px) {
    .SearchPageCom{
        overflow-x: hidden;
    }

    .searchCom {
        padding-left: 4%;
        padding-right: 4%;
    }

    .CategoryImg {
        height: 35vh;
    }

    .bannerCom{
        height: 30vh;
    }

    .ProductsCom {
        justify-content: center;
        margin-top: 30px;
    }

    .indieSerProduct {
        width: 45%;
        margin-bottom: 10px;
        padding: 20px 15px;
        height: auto;

    }

    .checkStore{
        font-size: 12px;
    }
    .indieSerProduct .price{
        font-size: 20px;
    }
    .indieSerProduct div {
        height: 80% !important;
    }
    .indieCarProduct b, .indieSerProduct b {

        font-size: 14px;
    }
    .indieCarProduct span, .indieSerProduct span{
        font-size: 12px;
        height: 30px;
        /*overflow-y: hidden;*/
    }
    .FilList{
        margin-top: 10px;
    }
    .IndieFilterCon{
        padding-top: 10px;
    }
}
